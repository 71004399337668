@import '../../../scss/theme-bootstrap';

.random-gift-picker {
  width: 100%;
  position: relative;
  min-height: 400px;
  @include breakpoint($bp--large-up) {
    min-height: 640px;
  }
  &__shop-now {
    color: $color--white;
  }
  &__see-details {
    padding-bottom: 4px;
    #{$ldirection}: 34%;
    bottom: 19px;
    color: $color--white;
    font-size: 14px;
    position: relative;
    border-bottom: 2px solid $color--white;
    @include breakpoint($bp--large-up) {
      top: 55px;
      padding-bottom: 7px;
      #{$ldirection}: 45%;
      font-size: 16px;
    }
  }
  &__overlay-terms {
    #{$rdirection}: 33%;
    top: 30px;
    position: relative;
    color: $color--white;
    @include breakpoint($bp--large-up) {
      #{$rdirection}: 45%;
      top: -32px;
    }
  }
  &__overlay-content-success {
    &-copy {
      @include breakpoint($bp--large-up) {
        margin: 25px;
        color: $color--white;
      }
    }
  }
  &__inactive-overlay-terms {
    margin: auto;
    font-size: 12px;
    padding-top: 30px;
    width: 84.375vw;
    text-align: $ldirection;
    @include breakpoint($bp--large-up) {
      position: absolute;
      bottom: 31px;
      padding-top: 0;
      width: calc(100% - 50px);
    }
  }
  &__overlay-inner-content-success,
  &__overlay-inner-content-inactive {
    text-align: center;
    width: 100%;
    margin-top: 15%;
    @include breakpoint($bp--large-up) {
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: 0;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  &__overlay-content-inactive,
  &__overlay-content-success {
    margin: auto 25px;
    font-family: $ano-bold_regular-font;
    color: $color--white;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    position: fixed;
    background-repeat: no-repeat;
    background-size: contain;
    top: 20%;
    @include breakpoint($bp--large-up) {
      margin: 0;
      position: absolute;
      background-size: 100% 100%;
      top: 0;
    }
    &-header {
      font-size: 40px;
      color: $color--white;
      padding-bottom: 10px;
      margin-top: 15%;
      @include breakpoint($bp--large-up) {
        font-size: 50px;
        margin-top: -2%;
      }
    }
    &-subheader {
      font-size: 20px;
      color: $color--white;
      padding-bottom: 10px;
      @include breakpoint($bp--large-up) {
        padding-bottom: 15px;
        font-size: 30px;
      }
    }
    &-info {
      font-size: 16px;
      font-family: $ano_regular-font;
      padding-bottom: 10px;
      @include breakpoint($bp--large-up) {
        padding-bottom: 15px;
        font-size: 20px;
      }
    }
    &-copy {
      @include breakpoint($bp--large-up) {
        margin: 10px 25px;
        font-size: 20px;
      }
    }
    &-button {
      padding: 14px 15px 0;
      font-size: 15px;
      text-transform: uppercase;
      cursor: pointer;
      background-color: $color--black;
      border-radius: 45px;
      height: 50px;
      font-weight: bold;
      margin-top: 14px;
      width: 70%;
      color: $color--white;
      text-decoration: none;
      @include breakpoint($bp--large-up) {
        width: 45%;
      }
      @include breakpoint($bp--medium-portrait) {
        width: 45%;
      }
    }
  }
  &__overlay-content {
    &--continue-button {
      margin-top: 0;
    }
    &-shopbtn {
      width: 32%;
      line-height: 1.5;
      margin-top: 7%;
      @include breakpoint($bp--large-up) {
        margin-top: 3%;
      }
    }
  }
  &__game-unavailable {
    width: 100%;
    height: 100%;
    z-index: 4;
    position: absolute;
    &-inner {
      text-align: center;
      font-size: 16px;
      display: inline-block;
      position: absolute;
      top: 25%;
      transform: translate(-50%, -50%);
      #{$ldirection}: 50%;
    }
    &-title {
      font-size: 40px;
      margin-bottom: 20px;
      margin-top: 0;
      line-height: 1;
    }
    .button {
      margin-top: 20px;
    }
  }
  &__loading-media {
    margin: auto;
    background-size: auto;
    background-position: center 25%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    .mantle-media-asset img,
    .mantle-media-asset picture,
    .mantle-media-asset video {
      width: 100%;
      object-fit: cover;
      height: 100%;
      @include breakpoint($bp--medium-portrait) {
        min-width: 100%;
      }
    }
  }
  &__loading {
    color: $color--white;
    font-family: $ano-bold_regular-font;
    width: 100%;
    height: 100%;
    z-index: 5;
    position: absolute;
    &--title {
      margin: 15% 0 5%;
      font-size: 40px;
    }
    &--subtext {
      font-size: 15px;
    }
    &--inner {
      position: absolute;
      top: 45%;
      transform: translate(-50%, -50%);
      #{$ldirection}: 50%;
      text-align: center;
      @include breakpoint($bp--large-up) {
        top: 50%;
      }
      @include breakpoint($bp--medium-portrait) {
        top: 45%;
      }
    }
    &--image {
      background-size: contain;
      background-repeat: no-repeat;
      height: 209px;
      width: 209px;
      margin: auto;
      @include breakpoint($bp--large-up) {
        height: 251px;
        width: 251px;
      }
    }
  }
}

#colorbox {
  &.random-gift-picker {
    &__overlay-container-inactive-overlay,
    &__overlay-container-success-overlay {
      top: 30%;
      height: 56%;
      background: transparent;
      #cboxLoadedContent {
        padding: 0;
        background: inherit;
      }
      #cboxContent {
        background: inherit;
      }
    }
  }
}
